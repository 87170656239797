/* NOTE: https://sass-lang.com/documentation/at-rules/import, this might have to be changed to @use eventually */
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

/* ---------------------------------------------
/ Global CSS
/ ---------------------------------------------*/

.info-button {
    border: 1px solid #d1d5db;
    transition: 
        background-color 0.2s, transform 0.2s,
        box-shadow 0.2s, transform 0.2s;

}
.info-button:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 

}
.info-button.yellow:hover {
    background-color: #fffef085;
}
.info-button.green:hover {
    background-color: #f3f8ef7a;
}
.info-button.blue:hover {
    background-color: #ecf2f57a;
}

:root, #root {
    --vc-blue: #5d98d1;
    --vc-light-blue: #cfdeec; 
    --vc-green: #a7b661;
    --vc-light-green: #e0e4ce; 
    --vc-yellow: #f1c31a;
    --vc-light-yellow: #f4e9bf; 
    --background-color: #f5f5f5;

    /* NOTE: all variants of primary, success, and warning will now be Vertical City colours. For instance, if a button should be green, use variant "success" */
    --primary: var(--vc-blue); 
    --success: var(--vc-green);
    --warning: var(--vc-yellow);
    --danger: #FF7F7F; /* changed to fit better with the colour of the other alerts */

    --alert-success-background: #edf0df;
    --alert-success-text: #646d3a;
    --alert-danger-background: #ffe5e5;
    --alert-danger-text: #ac7070;
    
    --positive: #98b02c;
    --negative: #f55656;

    --font-stack: 'proxima-nova', sans-serif; 

    --narrow-page-width: 1024px; /* this is only used for Home/Article/GetStarted pages - currently deprecated */

    --content-width: 1320px; /* most of In App pages */
    --content-width-narrow: 1200px; /* manage data and admin page */

    --divider-color: var(--vc-blue);

    --legend-size: 10px;
    --legend-border-radius: 100%;

    --video-height: 60;

    background-color: var(--background-color);
}


/* ---------------------------------------------
/ Generic CSS
/ ---------------------------------------------*/

/* make inner button font white */
.btn-primary,
.btn-success,
.btn-warning {
    color: #fff !important;
    font-weight: 525;
    font-family: var(--font-stack) !important;
}

.btn-primary {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.btn-success {
    background-color: var(--success) !important;
    border-color: var(--success) !important;
}

.btn-warning {
    background-color: var(--warning) !important;
    border-color: var(--warning) !important;
}

.btn-outline-success {
    color: var(--success) !important;
    border-color: var(--success) !important;
}

.btn-outline-success:hover {
    background-color: var(--success) !important;
    color: white !important;
    border-color: var(--success) !important;
}

.btn-outline-success:active, .btn-outline-success:focus {
    background-color: var(--success) !important;
    color: white !important;
    border-color: var(--success) !important;
}

.progress-bar.bg-primary {
    background-color: var(--primary) !important;
}

.progress-bar.bg-success {
    background-color: var(--success) !important;
}

.progress-bar.bg-warning {
    background-color: var(--warning) !important;
}

.dropdown-item:active,
.dropdown-item:focus {
    background-color: var(--vc-blue) !important;
}

/* Specific styles for success variant */
.dropdown-item.success:active,
.dropdown-item.success:focus {
    background-color: var(--success) !important;
}

/* Specific styles for warning variant */
.dropdown-item.warning:active,
.dropdown-item.warning:focus {
    background-color: var(--warning) !important;
}

.modal {
    a {
        color: var(--primary) !important;
        text-decoration: none;
    }
    
    a:hover {
        filter: brightness(85%) !important;
    }
}

.alert-success {
    background-color: var(--alert-success-background) !important;
    border-color: var(--alert-success-background) !important;
    color: var(--alert-success-text) !important;
}
.alert-danger {
    background-color: var(--alert-danger-background) !important;
    border-color: var(--alert-danger-background) !important;
    color: var(--alert-danger-text) !important;
}

.space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex {
    display: flex;
    flex-direction: row;
}

.flex-grow-1 {
    flex-grow: 1;
}

/* flex class that causes elements to stack when the screen is medium size */
.flex-column-breakpoint {
    display: flex;
    flex-direction: row;  
    @media (max-width: 980px) {
        display: flex;
        flex-direction: column;
    }    
}

/* flex class that causes elements to stack when the screen is mobile size */
.flex-column-mobile {
    display: flex;
    flex-direction: row;  
    @media (max-width: 560px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }     
}

.font-family {
    font-family: var(--font-stack);
}

/* ---------------------------------------------
/ Global Styling
/ ---------------------------------------------*/

/* Global Navbar Component */
.color-nav {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 8px rgba(0,0,0,.5);
    z-index: 2;

    .navbar-brand { 
        margin-right: 1rem;
    }

    .nav-link#home {
        color: var(--vc-blue);
        padding: 5px;
    }

    .nav-link#home-articles {
        color: var(--vc-green);
        padding: 5px;
    }

    .container-fluid#narrow {
        max-width: var(--narrow-page-width);
    }

    .nav-dropdown-item {
        display: flex;
        gap: 10px;
        align-items: center;
    
        b {
            font-size: 15px;
        }
    
        p {
            margin-bottom: 0;
            font-size: 12px;
        }
    }

    .beta {
        padding-top: 1.5%;
        padding-bottom: 2.5%;
        padding-left: 8.5%;
        padding-right: 8.5%;
        border-radius: 99em;
        text-decoration: none;
        font-weight: 600;
        font-size: 15px;
    }

    .beta#Hello {
        background-color: var(--vc-blue);
        color: #fff;
    }
    .beta#ReportCard {
        background-color: var(--vc-blue);
        color: #fff;
    }
    .beta#ForecastCalculator {
        background-color: var(--vc-green);
        color: #fff;
    }
    .beta#InvestmentDashboard {
        background-color: var(--vc-yellow);
        color: black;
    }
    .beta#Energy {
        background-color: var(--vc-blue);
        color: black;
    }
    .beta#Budget {
        background-color: var(--vc-green);
        color: black;
    }
    .beta#Chat {
        background-color: var(--vc-yellow);
        color: black;
    }
    @media (max-width: 980px) {
        .nav-item.dropdown a {
            font-size: 14px;
            padding: 6px;
        }
    } 
    @media (max-width: 560px) {
        .nav-dropdown-item {
            white-space: normal;
        }
    }               
}

.color-nav#chat {
    margin-left: 250px;
    @media (max-width: 980px){
        margin-left: 0px;
    }
}

.color-nav#chat {
    margin-left: 250px;
    @media (max-width: 980px){
        margin-left: 0px;
    }
}

.content {
    max-width: var(--content-width);
    margin: auto;
    padding: 20px;
}

/* offcanvas - shift page content right */
@media screen and (max-width: 2300px) {
    .content.show-offcanvas {
        max-width: 1670px; /* equal to breakpoint of @media block below */
        box-sizing: border-box;
        padding-left: 500px; /* equal to width of offcanvas component (see :root .offcanvas) */
    }
}
/* offcanvas - allow component to overlap page contents */
@media screen and (max-width: 1670px) {
    .content.show-offcanvas {
        max-width: 1170px;
        padding-left: 0px;
        margin-right: 0px;
    }
}

.component {
    margin: 10px 0px 15px 0px;
    width: 100%;
}

.chart-label, .component-container {
    padding: 20px;
    background-color: rgb(255, 255, 255);
    border: 0px;
    text-align: left;
    border-radius: 10px;
    box-shadow: rgb(59 59 59 / 5%) 0px 5px 15px 0px;
}

.help-button {
    padding: 2px;
    border: 0;
    border-radius: 30px;
    background-color: var(--background-color);
}
.help-button:hover {
    background-color: rgb(226, 227, 229);
}

.confirm-delete-button {
    background-color: #dc3545;
    color: #ffffff !important;
}

.confirm-delete-button:hover {
    background-color: #ac2a37 !important;
}

.understand-page-button {
    padding: 2px;
    border: 0;
    border-radius: 30px;
    background-color: rgb(226, 227, 229);
}

.understand-page-button:hover {
    background-color: rgb(180, 180, 182);
}

.onboarding-buttom {
    padding: 2px;
    border: 0;
    border-radius: 30px;
    background-color: #f9edc2;
}

.onboarding-buttom:hover {
    background-color: #fae596;
}

.button-hidden {
    border: 0px;
    background-color: inherit;
    outline: none;
}

.button-hidden-bordered {
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 4px; /* Optional: Adds rounded corners */
    background-color: inherit;
    outline: none;
}

.intro {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;

    h1,
    .information {
        font-family: var(--font-stack);
    }

    h1 {
        font-weight: 700;
        margin-bottom: 10px;
    }

    .information {
        font-weight: 400;
        text-align: center;
        font-size: 20px;
        margin-bottom: 0%;
        width: 80%;
        margin: auto;
        
        a {
            color: var(--primary) !important;
            text-decoration: none;
        }
        
        a:hover {
            filter: brightness(85%) !important;
        }
    }

    @media (max-width: 560px) {
        .information {
            font-size: 1rem;
            width: 95%;
        }
    }
}

/* headers */
.divider {
    flex-grow: 1;
    height: 2px;
    background-color: var(--divider-color);
}

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    padding-top: 20px;

    .left {
        flex-grow: 0;

        .section-header {
            display: inline-block;
            margin: 15px 10px;
            color: black;
            font-family: var(--font-stack);
            font-size: 20px;
            font-weight: 800;
            line-height: 1.2;
            
            @media (max-width: 980px) {
                font-size: 14px;
            }                
        }
        @media (max-width: 980px) {
            .section-header {
                font-size: 14px;
                margin: 15px 4px;
            }
            img {
                width: 40px;
                height: 40px;
            }
        }                
    }

    .left-pdf {
        flex-grow: 0;

        .section-header {
            display: inline-block;
            margin: 15px 10px;
            color: black;
            font-family: var(--font-stack);
            font-size: 20px;
            font-weight: 800;
            line-height: 1.2;             
        }
    }

    .header-button {
        padding-left: 10px;
    }

    .divider-1 {
        --divider-color: var(--vc-blue);
    }

    .divider-2 {
        --divider-color: var(--vc-green);
    }

    .divider-3 {
        --divider-color: var(--vc-yellow);
    }

    #blue:focus {
        border-color: var(--vc-light-blue);
        box-shadow: 0 0 0 0.25rem var(--vc-light-blue);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%235d98d1'/%3e%3c/svg%3e");
    }

    #blue:checked {
        background-color: var(--vc-blue);
        border-color: var(--vc-blue);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    #green:focus {
        border-color: var(--vc-light-green);
        box-shadow: 0 0 0 0.25rem var(--vc-light-green);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a7b661'/%3e%3c/svg%3e");
    }

    #green:checked {
        background-color: var(--vc-green);
        border-color: var(--vc-green);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    #yellow:focus {
        border-color: var(--vc-light-yellow);
        box-shadow: 0 0 0 0.25rem var(--vc-light-yellow);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f1c31a'/%3e%3c/svg%3e");
    }

    #yellow:checked {
        background-color: var(--vc-yellow);
        border-color: var(--vc-yellow);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    .switch {
        /*z-index: 1; */
        margin-top: 2px;
        padding-left: 20px;
    }

    .form-check-label {
        font-family: var(--font-stack);
        padding-left: 10px;
        font-size: 20px;
        margin-top: -3px;
    }

    @media (max-width: 980px) {
        button {
            padding: 4px;
        }
        .switch {
            padding-left: 10px;
            font-size: 14px;
            label {
                font-size: 14px;
                padding-left: 4px;
            }
        }
        .left {
            flex-basis: auto;
        }
    }        
}

.disclaimer {
    padding-top: 20px;
    margin-top: 40px;
    border-top: 1px solid rgb(230, 228, 227);
    font-size: 16px;
    color: rgb(115, 112, 110);
    @media (max-width: 560px) {
        font-size: 1rem;
    }
}

.section-description {
    font-family: var(--font-stack);
    margin-bottom: 10px;
    margin-left: 5px;

    a {
        color: var(--primary) !important;
    }
    
    a:hover {
        filter: brightness(85%) !important;
    }

    @media (max-width: 560px) {
        font-size: 1rem;
    }
}

/* Product Tour Component */
.product-tour-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;

    .highlight {
        position: absolute;
        box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.7);
        /*background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); */
        z-index: 1;
    }

    .box {
        display: inline-block;
        width: 100px;
        height: 100px;
        margin-top: 50px;
        text-align: center;
        padding-top: 2em
      }
      
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
         
        .top {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: rgba(0, 0, 0, .7);
            z-index: 2;
            pointer-events: auto;
        }
        
        .left {
            position: absolute;
            left: 0;
            /*width: 0%; */
            background: rgba(0, 0, 0, .7);
            z-index: 2;
            pointer-events: auto;
        }
        
        .right {
            position: absolute;
            right: 0;
            background: rgba(0, 0, 0, .7);
            z-index: 2;
            pointer-events: auto;
        }
        
        .bottom {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            background: rgba(0, 0, 0, .7);
            z-index: 2;
            pointer-events: auto;
            /*overflow-y: clip; */
        }
    }

    .tour-tooltip {
        font-family: var(--font-stack);
        position: fixed;
        background-color: white;
        border: 1px solid black;
        width: 350px; /* if this is changes, you must also change line tooltipWidth in createTooltip() in ProductTour.js */
        border-radius: 10px;
        z-index: 3;

        .banner {
            font-weight: 600;
            font-size: 20px;
            background-color: var(--vc-blue);
            padding: 4px 8px;
            border-radius: 8px 8px 0 0; /* Top corners rounded */
            width: 100%;
            height: 38px;
            color: white;
        }

        .body {
            padding: 8px;
            border-radius: 0 0 8px 8px; /* Bottom corners rounded */
        }
    }
}

.tour-highlight {
    position: relative;
    z-index: 101;
}

/* Global left/right arrow buttons */
.arrow-button {
    display: flex;
    color: white;
    background-color: var(--vc-blue);
    padding: 5px 10px;
    margin: auto 10px;
    border: 0;
    border-radius: 20px;
    transition: all .3s ease;
    font-weight: bold;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
}
.arrow-button#disable {
    cursor: default;
    background-color: #5d99d16b;
}

.arrow-button > .arrow {
    width: 6px;
    height: 6px;
    border-right: 2px solid #C2FFE9;
    border-bottom: 2px solid #C2FFE9;
    position: relative;
    transform: rotate(-45deg);
    margin: 0 6px;
    transition: all .3s ease;
}
.arrow-button > .arrow#left {
    transform: rotate(135deg);
}

.arrow-button:hover {
    background-color: #1271c9;
    color: #fff;
}

/* Missing data component */
.missing-building,
.missing-data {
    text-align: center;
    padding-top: 10%;
    padding-bottom: 10%;
    max-width: var(--content-width);
    margin: auto;
    
    h1 {
        margin-bottom: 16px;
    }

    button {
        margin-top: 16px;
    }

    @media (max-width: 560px) {
        h1 {
            font-size: 25px !important;
        }
        h5 {
            font-size: 16px !important;
        }
    }
}

/* Global size of all switches */
.form-switch .form-check-input {
    transform: scale(1.6) !important;
    cursor: pointer !important;
}

.legend-size {
    width: var(--legend-size);
    height: var(--legend-size);
    border-radius: var(--legend-border-radius);
    margin-right: 10px;
}

/* Global Highcharts */
.chart-label {
    font-family: var(--font-stack);
    background-color: rgba(255, 255, 255, 0.75);
    border: 1px solid rgba(0, 0, 0, 0.25);

    .label-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 4px 4px 4px 4px;
        font-size: 15px;

        .circle-legend {
            --legend-size: 10px;
            --legend-border-radius: 100%;
        }

        .square-legend {
            --legend-size: 20px;
            --legend-border-radius: 10%;
        }
    }
}

/* shared between energy and reserve fund, maybe rename to something better? */
.alert-info p {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: var(--font-stack);
    font-weight: 600;
    font-size: 16px;
}

.alert-detail {
    width: 100%; /* for energy summary */
    padding: 10px !important;
    margin-bottom: 10px !important;

    @media(max-width: 980px){
        padding: 5px !important;
        margin-bottom: 5px !important;
        p {
            font-size: 12px;
            img {
                min-width: 20px !important;
                min-height: 20px !important;
                width: 20px !important;
                height: 20px !important;
            }
        }
    }
}
.alert-detail#last {
    margin-bottom: 0px;
}

.alert-banner {
    .alert {
        position: absolute;
        left: 0;
        right: 0;
        padding: 10px 0px;
        margin: 10px 1px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 0px;

        .container {
            max-width: var(--content-width);
            margin: auto;
            padding-right: min(40vw,585px);
        }
    }

    .underline-button {
        border: none;
        background: none;
        padding: 0;
        text-decoration: underline;
        margin-left: 10px;
    }
    
    @media (max-width: 980px) { 
        font-size: 12px;
        
        .alert {
            margin-bottom: 0;
            padding: 6px;
        }    
    }
    
    @media (max-width: 560px) { 
        font-size: 11px;
        .alert .container {
            padding-right: 10vw;
        }
    }
}

/* Global React Table */
.react-table {
    /* padding: 10px 0; */
    overflow-y: scroll;
    font-family: var(--font-stack);
    border-radius: 10px;
    border-collapse: collapse;
    border-spacing: 0;
    background-color: white;

    .stick-header-style {
        background-color: white;
        position: sticky;
        top: 0px;
        /* border: 3px solid black; */

        .header-hover {
            cursor: pointer;

            &:hover {
                background-color: #D3D3D3;
            }

            /* .sorter {
                 display: inline-block;
                 font-size: 7px;
                 margin-left: 5px;

                 #sorted-ascending,
                 #sorted-descending {
                     color: black;
                 }

                 #unsorted-descending,
                 #unsorted-ascending {
                     color: grey;
                 }

                 #sorted-ascending,
                 #unsorted-ascending {
                     margin-bottom: -3px;
                 }
             } */
        }
    }

    /* below removes default react bootstrap border */
    > :not(:first-child) {}
}

/* --------------------------------------------------------------------------------
/ Page Components
/ --------------------------------------------------------------------------------*/

/* used by Home and Articles pages */
.video-parent {
    width: 100%;
    height: var(--video-height) * 1vh;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .text {
        position: absolute;
        top: 66px; /* same height as navbar */
        left: 0;
        width: 100%;
        height: var(--video-height) * 1%; 
        color: #fff;
        flex-direction: column;
    }

    h1 {
        font-size: 3rem;
        font-weight: 800;
        margin-bottom: 10px;
        text-shadow: rgb(0 0 0 / 40%) 0px 4px 5px;
    }

    h3 {
        font-weight: 500;
        text-shadow: rgb(0 0 0 / 40%) 0px 4px 5px;
    }

    @media (max-width: 980px) {
        h1, h3 {
            font-size: 1.5rem;
        }
        .text {
            text-align: center;
        }
    }        

}

/* --------------------
/ Non App Pages
/ --------------------*/

/* static home page */
.Article, .Articles, .Home {
    --content-height: 550px;
    
    /* some general mobile breakpoint classes */
    @media (max-width: 980px) {
        .remove-for-mobile {
            display: none;
        }

        .mobile-padding-horizon {
            padding-left: 12px !important;
            padding-right: 12px !important;
        }

        .mobile-padding-vertical {
            padding-top: 18px !important;
            padding-bottom: 22px !important;
        }
    }            

    h5 {
        font-size: 1.15rem;
        font-weight: 300;
        @media (max-width: 980px) {
            font-size: 1.05rem;
            line-height: 1.5;
        }            
    }

    .video-container {
        --video-height: 60;
    }

    .panel {
        background-image: linear-gradient(180deg, var(--background-color), #fff);

        .panel-highlight {
            max-width: var(--narrow-page-width);
            flex-direction: row;
            margin: auto;
            height: 500px;
            
            @media (max-width: 980px) {
                img {
                    height: 40%;
                    width: 40%;
                }
                height: auto;
                padding-top: 10px;
                padding-bottom: 10px;
            }        

            .highlight-text {
                text-align: left;

                h1 {
                    font-size: 2rem;
                    font-weight: 800;
                    margin-bottom: 20px;
                    @media (max-width: 980px) {
                        font-size: 24px;
                        text-align: center;
                        margin-top: 6px;
                    }            
                }
            
                #yellow {
                    color: var(--vc-yellow);
                }
                #blue {
                    color: var(--vc-blue);
                }
                #green {
                    color: var(--vc-green);
                }
            }

            .highlight-text#left {
                padding-right: 5%;
            } 
            .highlight-text#right {
                padding-left: 3%;
            }
        }
        
        .panel-content#articles {
            height: auto;
            padding-top: 60px;
            @media (max-width: 980px) {
                padding-top: 30px;
            }        
        }

        .panel-content {
            flex-direction: column;
            height: var(--content-height);
            max-width: var(--narrow-page-width);
            margin: auto;
            text-align: center;
            @media (max-width: 980px) {
                height: auto;
            }        

            img {
                margin-bottom: 50px;
                @media (max-width: 980px) {
                    margin-top: 12px;
                    margin-bottom: 12px;
                }        
            }
    
            h1 {
                font-size: 2.5rem;
                font-weight: 800;
                margin-bottom: 30px;
                color: #000000;
            }

            h3 {
                font-weight: 300;
            }
            @media (max-width: 980px) {
                h1, h3 {
                    font-size: 1.2rem;
                }
            }
        }

        .articles-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-top: 30px;
            padding-bottom: 60px;
            @media (max-width: 980px) {
                justify-content: center;
            }            

            .article {
                width: 33.33%;
                border: 0px;
                position: relative;
                padding: .5%;
                background-color: transparent;
                @media (max-width: 980px) {
                    width: 80%;
                }            

                h1 {
                    font-size: 2rem;
                    font-weight: 800;        
                    position: absolute;
                    top: 68%;
                    left: 4%;
                    width: 95%;
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: left;
                    @media (max-width: 980px) {
                        font-size: 24px;
                        top: 8%;
                    }            
                }
    
                h1#negativity {
                    top: 55%;
                    @media (max-width: 980px) {
                        top: 4%;
                    }            
                }
            }
        }
        
        .panel-content#footer {
            height: var(--content-height) - 200;
            @media (max-width: 980px) {
                height: var(--content-height) - 250;
            }            
        } 
    }

    .button-container, .logo-container {
        flex-direction: column;
        padding-bottom: 60px;
        background-color: #fff;
    }

    .footer {
        background-color: #fff;
        text-align: right;
        padding-bottom: 5px;

        #narrow {
            max-width: var(--narrow-page-width);
            align-items: center;
            margin: auto;
            @media (max-width: 980px) {
                padding-left: 0px;
                padding-right: 0px;
                flex-direction: column;
                text-align: center;
                padding-bottom: 10px;
                img {
                    margin-top: 6px;
                    margin-bottom: 20px;
                }
            }
        }
        span {
            font-weight: 10;
        }
    }

    
}

/*signup page */
.GetStarted {
    background-color: #fff;
    
    form {
        display: flex;
        flex-direction: column;
        max-width: 670px;
        margin: auto;
        padding-bottom: 100px;

        input {
            margin-bottom: 3%;
            padding: 2%;
            padding-top: 3%;
            padding-bottom: 3%;
        }

        button {
            width: 120px;
        }
    }
}

/* Articles Page */
.Article, .Articles {

    .video-container {
        --video-height: 38;
    }
    
    h1#explore-title {
        text-align: center;
        font-size: 3rem;
        font-weight: 800;
        margin-top: 20px;
        margin-bottom: 30px;
        @media (max-width: 980px) {
            font-size: 2rem;
        }        
    }

    .articles-container#articles-page {
        max-width: var(--narrow-page-width);
        align-items: center;
        margin: auto;
        padding-top: 0px;
        
        .article h1 {
            top: 73%;
            @media (max-width: 980px) {
                top: 4%;
            }
        }

        .article#articles-page {
            width: 50%;
        }
    }
}

.Article {
    /* nullify height for varying content lengths */
    .panel {
        .panel-content {
            height: auto;
            text-align: left;
            padding-bottom: 60px;
            
            b {
                font-weight: 500;
            }

            h1 {
                margin-top: 30px;
            }

            h2 {    
                color: var(--vc-blue);
                font-size: 1.8rem;
                font-weight: 800;
                margin-bottom: 20px;
                margin-top: 20px;
                margin-bottom: 20px;    
            }
        
            h3 {
                color: var(--vc-green);
                font-size: 1.6rem;
                font-weight: 700;
                margin-top: 20px;
                margin-bottom: 20px;    
            }

            #see-all-articles {
                padding-top: 30px;
                cursor: pointer;
                margin: auto;
            }
        }
    }
}

/* login page */
.ResetPassword, .ForgotPassword, .Login {
    padding: 60px;
    margin: 60px auto;
    width: 460px;
    height: 75%;
    background-color: rgb(255, 255, 255);
    border-radius: 25px;
    text-align: center;

    a {
        color: var(--primary) !important;
    }
    
    a:hover {
        filter: brightness(85%) !important; /* Adjust for a darker hover effect if supported */
    }

    button {
        margin-top: 24px;
        margin-bottom: 12px;
    }

    form {
        margin: 0 auto;
        max-width: 320px;
    }

    h2 {
        font-family: var(--font-stack);
        font-weight: 900;
    }

    p,
    label {
        font-family: var(--font-stack);
        font-weight: 400;
    }

    .form-check {
        text-align: left;
    }

    .form-control {
        margin-top: 6px;
    }

    .details {
        margin-top: 6px;
        align-items: center;
    }

    @media (max-width: 980px) {
        padding: 30px;
        margin: 30px auto;
        max-width: 300px;
        width: auto;
        height: 75%;
        h2 {
            font-size: 18px
        }
        p, label, a, input {
            font-size: 14px;
        }
        button {
            font-size: 16px;
            padding: 5px 10px;
        }
    
    }      
}

/* forgot password page */
.ResetPassword, .ForgotPassword {
    .alert-success {
        margin-top: 12px;
    }
}

/* reset password page */
.ResetPassword {
    
    .password-title {
        margin-bottom: 30px;
        text-align: left;
    }

    .password-group {
        width: 400px;
        align-items: center;
        text-align: center;

        p {
            margin-bottom: 0px;
        }
    }   
}

.PageNotFound {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 400px;
        height: 400px;
    }

    h1 {
        font-size: 180px;
    }    

    @media (max-width: 980px) {
        img {
            width: max(30vw, 130px);
            height: max(30vw, 130px);
        }

        h1 {
            font-size: 18vw
        } 

        h3 {
            font-size: clamp(9.3px,3vw, 26px)
        }

        button {
            font-size: clamp(9px,2vw, 16px);
        }

    }
}

/* --------------------
/ App Pages
/ --------------------*/

.Admin {
    .content {
        max-width: var(--content-width-narrow);
    }

    .admin-dashboard-buttons {
        margin: 20px 0px;
        padding: 0;
    }
}

.Settings {
    font-family: var(--font-stack);
    margin: 60px auto;
    width: 800px;
    height: 75%;
    text-align: center;

    .setting-widget {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .setting {
        display: flex;
        justify-content: space-between;
        width: 300px;
        padding-top: 10px;
    }

    .setting-label {
        font-size: large;
    }

    h1 {
        font-weight: 700;
        margin-bottom: 10px;
    }


}

.Chat {
    --max-width: 680px;
    font-family: var(--font-stack);
    display: flex;
    flex-direction: column;
    position: relative; 
    height: 94.5vh;
    overflow: hidden;
    transition: margin 0.3s ease ;

    /* hide scrollbar TODO make it only hidden */
    .content::-webkit-scrollbar {
        display: none;
    }
    
    .content {
        margin: 0px auto;
        height: 88.75vh;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        left: 25%;  /* Center horizontally */
        right: 25%;  /* Center horizontally */
    }

    

    #anchor {
        overflow-anchor: auto;
        height: 1px;
    }

    .first-prompt {
        position: relative;
        text-align: center;
        margin: auto;
        
        img {
            display: block;
            margin: auto;
        }
        h2 {
            font-size: 28px;
            font-weight: 600;
        }
    }

    .prompt {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        /* hide scrollbar */
        textarea::-webkit-scrollbar {
            display: none;
        }
        
        textarea:focus {
            /*border-color: ; Border color when textarea is focused */
            outline: none; /* Optional: removes the default focus outline */
            /* Other styling for focus state */
        }
        
        textarea {
            /*positioning */
            width: var(--max-width);
            max-width: 95vw;
            padding: 10px;
            display: flex;
            justify-content: center;
            margin: auto;
            max-height: 180px;
            height: 44px;
            /* styling */
            border-radius: 15px;
        }
        span {
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 13px;
            color: grey;
            margin: 5px 0;
        }
    }

    .example-prompts-container {
        width: var(--max-width);
        max-width: 95vw;
        position: absolute;
        bottom: 90px;
        left: 0;
        right: 0;
        margin: auto;
        
        button {
            width: 48%;
            min-width: 290px;
            margin: 3px;
            height: 60px;
            border-radius: 10px;
            text-align: left;
            font-weight: 600;
            color: #3f3e3e;
            border-color: #b1a9a9;

            @media (max-width: 630px) {
                width: 99%;
            }
        }

        @media (max-width: 560px) {
            bottom: 100px; 
        }
    }

    .messages {
        display:flex;
        flex-direction: column;
        gap: 20px;
        width: var(--max-width);
        max-width: 95vw;
        padding-bottom: 45px;
        overflow-y: auto;
        max-height: 87.75vh;

        .message {
            
            
            .chat-output * {
                margin-bottom: 5px;
            }
            
            .chat-text  {
                display: flex;
                flex-direction: column;
            }

            h5 {
                font-weight: 600;
                margin: 5px 0;
            }

            pre {
                font-family: var(--font-stack);
                font-size: inherit;
                white-space: pre-wrap;
                margin-bottom: 0px;
            }

            .circle-legend {
                --legend-size: min(1.5vw,10px);
                --legend-border-radius: 100%;
                margin-left: 5px;
                display: inline-block;
                background-color: #000000;
            }
        }
    }

    /* hide scrollbar */
    .messages::-webkit-scrollbar {
        display: none;
    } 



    .sidebar {
        .sidebar-container {
            width: 250px;
            height: 100%;
            position: fixed;
            top: 0;
            left: -250px;
            background-color: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.064);
            z-index: 1;
            transition: left 0.3s ease ;

            .prev-chat-button-container {
                height: 89%;
                overflow-y: auto;
            }

            /* style scrollbar */
            .prev-chat-button-container::-webkit-scrollbar {
                width: 14px; 
            } 

            /* style scrollbar */
            .prev-chat-button-container::-webkit-scrollbar-thumb {
                background-color: #f0f0f0;    
                border-radius: 20px;       
                margin-right: 3px;
                border: 3px solid white;  
            } 
    
            .chat-history-button {
                display: flex;
                margin: 5px;
                width: 95%;
                border-radius: 10px;
                border-width: 0px;
                background-color: #f0f0f0;
            }

            .chat-history-button.selected {
                background-color: #adb5bd;
            }

            .delete-button {
                display: flex;
                height: 25px;
                width: 25px;
                margin-top: 7px;
                border-radius: 20px;
                border-width: 0px;
                background-color: inherit;
            }

            .delete-button:hover {
                background-color: #ced4da;
            }

            .chat-history-container {
                position: relative;
            }

            .options-container {
                display: none;
            }

            .chat-history-container:hover .options-container {
                display: block;
            }

            .chat-history-button.selected .options-container {
                display: block;
            }
    
            p {
                font-family: inherit;
                color: #3f3e3e;
                font-weight: 600;
                margin-left: 5px; 
            }
    
            p#button {
                margin: auto;
                margin-left: 5px; 
            }
    
            p#heading {
                font-size: 16px;
                margin-top: 30px;
                margin-bottom: 0px;
            }
        }
    
        .sidebar-container.open {
            left: 0; /* Applying left: 0 when the sidebar is open */
        }

        .arrow-button {
            position: fixed;
            top: 50%;
            left: 250px;
            padding: 0;
            z-index: 1;
            height: 30px;
            width: 18px;
            align-items: center;
            transition: left 0.3s ease;
        }

        .arrow-button.closed {
            left: 0px;
        }
    }
}

/* landing page */
.landing {
    font-family: var(--font-stack);
    margin: 60px auto;
    width: 800px;
    height: 75%;
    text-align: center;

    .missing-building {
        margin-top: 10%;
        font-size: 50px;
        text-align: center;
    }
    
    #SelectBuildingTour {
        min-width: 360px;
        margin-top: 32px;
    }

    .landing-summary {
        margin-top: 70px;
        margin-bottom: 40px;

        h1 {
            font-weight: 700;
            margin-bottom: 10px;
        }

        #default-button {
            margin-top: 10px;
        }
        
        .tour-highlight {
            .dropdown-menu {
                margin-top: 79px;
            }
        }
    }

    p#label {
        font-family: var(--font-stack);
        font-weight: 600;
    }

    .button-group {
        display: flex;
        flex-direction: column;
        align-items: center;

        button:hover {
            background-color: rgb(255, 255, 255);
        }

        button {
            padding: 20px;
            background-color: rgb(255, 255, 255) !important;
            border: 0px;
            text-align: left;
            border-radius: 10px;
            box-shadow: rgb(59 59 59 / 5%) 0px 5px 15px 0px;
            width: 550px;
            margin-top: 20px;
            font-size: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .divider {
            margin-top: 20px;
            width: 540px;
            border: 1px solid #000000ac;
        }
    
        .button-text {
            text-align: left;
            padding-left: 10px;
            background-color: rgb(255, 255, 255);
            border: 0px;

            display: flex;
            align-items: left;
            flex-direction: column;

            b {
                font-size: 18px;
                color: black;
            }

            p {
                font-weight: 400;
                margin-bottom: 0px;
                color: grey;
                font-size: 16px;
            }
        }
    }

    @media (max-width: 980px) {
        max-width: 340px;
        width: auto;
        .landing-summary button {
            font-size: 16px;
            padding: 6px 12px;
        }
        .button-group button {
            padding: 10px;
            margin: 10px 6px 6px;
            img {
                width: 40px;
                height: 40px;
            }
        }
    }

    @media (max-width: 560px) {
        max-width: 340px;
        width: auto;
        overflow-x: hidden;
        .landing-summary {
            margin-top: 20px;
        }
        #SelectBuildingTour {
            min-width: 200px;
            max-width: 330px;
            width: 95vw;
            white-space: normal;
        }
        .button-group button {
            max-width: 340px;
            width: 95vw;
        }
    }
}



/* reserve-fund page */
.hello, .reserve-fund {

    .inputs#benchmark {
        input[type=range]::-webkit-slider-thumb {
            background: #ced4da !important;
        }
    }

    #ReportCardRFS {
        min-width: 300px;
    }

    .study-dropdown {
        .dropdown-menu {
            right: auto !important;
            left: 50% !important;
            transform: translate(-50%, 0) !important;
            -webkit-transform: translate(-50%, 0);
            -o-transform: translate(-50%, 0);
            margin-top: 48px;
        }
    }

    /* inputs */

    .inputs {
        font-family: var(--font-stack);
        
        #disabled {
            background-color: rgb(255, 255, 255,0.5);
            color: rgb(33,37,41, .5);
        }

        p {
            font-size: 13px;
            color: grey;
            margin-bottom: 2px;
        }
        h5 {
            margin-bottom: 2px;
            font-size: 20px;
            font-weight: 600;
        }

        .input {
            .increment {
                margin-top: -6px;
                border: 0px;
                background-color: white;
                font-size: 10px;
                padding: 0px;
            }
            .increment#left {
                padding-right: 2px;
            }
            .increment#right {
                padding-left: 2px;
            }

            h5 {
                font-size: 20px;
                font-weight: 200;
            }

            h5#bold {
                font-size: 20px;
                font-weight: 600;
            }

            .slider#disabled {
                input[type=range]::-webkit-slider-thumb {
                    background: #a7b661CC  !important;
                }
                input[type=range]::-moz-range-thumb {
                    background: #a7b661CC  !important;
                }
            }

            .slider {
                p {
                    text-align: right;
                    margin-top: -6px;
                }

                .range-slider {
                    width: 150px;
                    padding-bottom: 0px;

                }
                input[type=range]::-webkit-slider-thumb {
                    background: var(--vc-green)  !important;
                }
                input[type=range]::-moz-range-thumb {
                    background: var(--vc-green)  !important;
                }
            }
            
            @media (max-width: 560px) {
                h5 {
                    font-size: 16px;
                }
                h5#bold {
                    font-size: 18px;
                }
                .slider .range-slider{
                    width: 125px;
                }
            }
        }

        #top {
            padding-top: 8px;
            button {
                font-size: 20px;
                padding-left: 10px;
            }
        }

        .modal-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            p {
                width: 62%;
            }
        }
    }

    .component#reserve-chart {
        margin-bottom: 0px;

        #recalculating {
            height: 540px;
            flex-direction: column;
            margin: auto;
            img {
                display: block;
            }
        }

        /* This is necessary to prevent highcharts from snapping to top of page, since container would have 0 height briefly!*/
        .component-container#reserve-chart {
            min-height: 100px;
            padding: 10px;
        }

        .chart-label {
            background-color: rgba(255, 255, 255);
    
            .label-details {
                font-size: 15px;
                font-weight: 300;
    
                .label-group {
                    margin-top: 0px;
                    margin-bottom: 0px;
                }    
            }
    
            h2 {
                font-size: 30px;
                font-weight: 500;
            }
    
            .circle-legend {
                &#study-bm {
                    background-color: var(--vc-yellow);
                }
    
                &#vc-bm {
                    background-color: var(--vc-green);
                }
    
                &#true-bm {
                    background-color: var(--vc-blue);
                }
            }
    
            .square-legend {
                width: 10px;
                height: 10px;
    
                &#true-bm-range {
                    background-color: #5d99d175;
                }
    
                &#vc-bm-range {
                    background-color: #a6b6617e;
                }
            }
    
            @media (max-width: 980px) {
                display: none; /* hide label when screen is small */
    
                h2 {
                    font-size: 18px;
                }
                .label-details {
                    font-size: 12px;
                    .label-group {
                        font-size: 12px;
                    }
                }
            }            
        }
    
        .data-label {
            font-weight: 600;
            font-size: 12px;
            background-color: white;
            border-radius: 10px;
            padding: 0px 4px;
            border: 1px solid #000000ac;
        }

        .data-label#green {
            color: var(--positive) !important;
        }
        .data-label#red {
            color: var(--negative) !important;
        }

        .legend-label {
            font-weight: 600;
            font-size: 12px;
            background-color: white;
            /* border-radius: 10px; */
            padding: 0px 4px;
            /* border: 1px solid #000000ac; */
        }

        .small-gap {
            gap: 5px;
            justify-content: left;
            margin-bottom: 2px;
        }
    }

    .grade-detail .summary-info {
        margin-left: 5px;
    }

    .component-container#settings {
        box-shadow: none;
        padding: 0;
        background-color: var(--background-color);
        
        --equal-width: 250px;
        #left {
            display: flex;
            width: var(--equal-width);
            padding-top: 20px;

            p {
                padding-left: 2px;
                line-height: 1;
                font-family: var(--font-stack);
                color: #999999;
                text-align: left;
                font-size: 12.5px;
                margin-bottom: 0px;
                width: 250px;
            }
        }
        #right {
            width: var(--equal-width);
            #see-inputs {
                margin-top: 27.5px;
                margin-left: 52px;
            }
            .risk-slider {
                float: right;
            }
        }

        .risk-slider {
            margin: auto;
            width: 23%;
            border: 0px;
            height: 73px;

            .range-slider {
                width: 100%; /* Make the slider fill the parent container */
            }

            .range-slider__wrap {
                width: 100%;
                display: flex;
            }
              
            .labels {
                padding: 0px 3px;

                #value {
                    font-size: 16px;
                    color: grey;
                    text-align: right;
                    margin-top: 5px;
                }
            }

            /* for removing border after button is clicked */
            button::-moz-focus-inner,
            input[type=button]::-moz-focus-inner {
                border: 0px;
            }

            span {
                width: 250px;
            }

            input[type=range] {
                margin-left: 5px;
                margin-right: 5px;
                padding: 0;
                margin-top: -10px;
            }

            &#benchmark,
            &#optimal {
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 15px;
                border-radius: 25px;
                border-collapse: separate;

                p {
                    margin-bottom: 0px;
                    text-align: center;
                    font-family: var(--font-stack);
                    font-size: 20px;
                    font-weight: 500;
                    padding-top: 5px;
                }
            }

            &#benchmark {
                background-color: var(--background-color);

                input[type=range]::-webkit-slider-thumb {
                    background: #ced4da !important;
                }

                p {
                    visibility: hidden;
                }

                #gear {
                    opacity: 0.4;
                }
            }

            &#optimal {
                background-color: white;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
                transition: box-shadow 1s ease-in-out;

                input[type=range]::-webkit-slider-thumb {
                    background: var(--vc-blue)  !important;
                }
            }
        }
    }

    .report-card {
        min-height: 330px;

        @media (max-width: 980px) {
            min-height: 370px;
        }
        @media (max-width: 560px) {
            min-height: auto;
        }
      
    }

    .report-card-pdf {
        min-height: 330px;
    }

    /* Report card component at top of report card page */
    .component-container#meter {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        text-align: left;
        /* padding-top: 8px; */
        /* padding-bottom: 8px; */
        
        b {
            margin-bottom: 10px;
        }
        h1 {
            font-size: 60px;
            font-weight: 800;
            margin-top: -5px;
            margin-bottom: 2px;
        }
        h5 {
            font-size: 24px;
            font-weight: 600;
        }
        .p {
            margin-bottom: 0px;
        }
        .p-pdf {
            margin-bottom: 0px;
        }

        --progressbar-width: 67%; 
        /* .progressbar-semicircle {
                width: $progressbar-width;
                aspect-ratio: 2 / 1;
                border-radius: 50% / 100% 100% 0 0;
                position: relative;
                overflow: hidden;
                // center content
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-top: 23%;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: conic-gradient(
                   from 0.75turn at 50% 100%, 
                   $primary calc(var(--value) * 1% / 2), 
                   #e9ecef calc(var(--value) * 1% / 2 + 0.1%));
                mask: radial-gradient(at 50% 100%, white 55%, transparent 55.5%);
                mask-mode: alpha;
                -webkit-mask: radial-gradient(at 50% 100%, #0000 55%, #000 55.5%);
                -webkit-mask-mode: alpha;
                counter-reset: percentage var(--value);
                font-size: 60px;
                font-weight: 800;
            } */

        .progressbar {
            margin-top: 5px;
        }
        .progressbar-pdf {
            margin-top: 5px;
        }

        .progressbar-semicircle {
            width: var(--progressbar-width);
            position: relative;    
            margin: 20px auto 0px auto;  
            text-align: center;     
            overflow: hidden;   
        }

        @media (max-width: 980px) {
            .progressbar-semicircle{
                /* min-width: 0px; */
                width: 95%;
            }
            h1{
                font-size: max(25px,6vw);
            }
            h5 {
                font-size: clamp(11px,4vw,25px);
            }
            .p {
                font-size: max(10px,2vw);
            }
        }

        @media (max-width: 560px) {
            width: 100% !important;
            margin-bottom: 10px;
            margin-left: 0px !important;
            margin-right: 0px !important;
            padding: 5px;
            .progressbar {
                height: 10px;
                margin-top: 2px;
            }
            .progressbar-semicircle{
                /* min-width: 0px; */
                width: 65%;
            }
            
        }
    }

    @media (max-width: 560px) {
        overflow-x: hidden;
        #ReportCardRFS {
            min-width: 150px;
            max-width: 250px;
            width: 75vw;
            white-space: normal;
            font-size: 16px;
        }
    }
}



.hello {
    .title {
        margin-top: 30px;
    }

    h1,
    .information {
        font-family: var(--font-stack);
    }

    h1 {
        text-align: center;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .information {
        font-weight: 400;
        text-align: center;
        font-size: 20px;
        margin-bottom: 0%;
        width: 90%;
        margin: auto;
        margin-bottom: 16px;

        a {
            text-decoration: none;
        }
    }

    h2 {
        text-align: center;
        padding: 10px;
        font-family: var(--font-stack);
        font-weight: 600;
        margin-bottom: 0px;
    }

    .select-study {
        margin: auto;
        color: red;
    }

    #report-card.component-container {
        margin-left: 17.5%;
        margin-right: 17.5%;
        h2 {
            margin: 0px;
            margin-bottom: 1.5%;
            padding: 0px;
            text-align: left;
        }


        .report-card {
            display: flex;
            
            p {
                text-align: left;
                margin-left: 3%;
                margin-right: 2%;
                width: fit-content;
            }

            .grade {
                padding: 6px 10px;
                margin-bottom: 4px;
                height: 75%;
                width: 10%;
                background-color: rgba(20, 22, 22, 0.164);
                border-radius: 25px;
                text-align: center;
                font-family: var(--font-stack);
                font-weight: 600;
                font-size: 40px;
            }
        }
    }
}

.header#projected-reserve-fund {
    .left {
        flex: 0 0 295px;
        @media (max-width: 980px) {
            flex-basis: auto;
        }
    }

    /* Switch Buttons - the following flagged values need to be tweaked when labels change (e.g. Benchmark / Custom) */
    /* TODO dynamic mobile styling */
    #smaller-divider-rf {
        flex: 0 1 480px;
        margin-left: 0%;
    }

    #larger-divider-rf {
        flex: 0 1 700px;
        margin-left: 2em; /* Switch Buttons */
    }

    .switch-buttons {
        display: flex;
        margin-top: 0%;
        padding-left: 10px;
        padding-right: 10px;
        
        div#switch-button1 {
            width: 120px; /* Switch Buttons */
            #switch-button1 {
                padding: 6px 15%; /* Switch Buttons */
                border-bottom-right-radius: 0px;
                border-top-right-radius: 0px;
            }
        }

        div#switch-button2 {
            width: 70px; /* Switch Buttons */
            #switch-button2 {
                padding: 6px 30.5%; /* Switch Buttons */
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
                white-space: nowrap;
            }
        }


        @media (max-width: 980px) {
            width: 80px;
            padding-right: 80px;
            padding-left: 0;
           /* margin-left: ; */
        }        
    }
}

/* shared between energy, calculator, and report card pages */
.detail-charts {
    flex-wrap: wrap;

    .component-container#detail-chart {
        width: 49.5%;
        padding: 20px;
        padding-left: 10px;
        padding-bottom: 5px;
    }

    @media (max-width: 980px) {
        flex-direction: column;
        .component-container#detail-chart {
            width: 100%;
            margin: 1%;
        }    
    }    
}
.detail-charts-pdf {
    flex-wrap: wrap;

    .component-container#detail-chart {
        width: 49.5%;
        padding: 20px;
        padding-left: 10px;
        padding-bottom: 5px;
    }  
}

/* energy page */
.energy {

    /* getting rid of component-containers for now
        .component-container {
            background-color: #f5f5f5;
            box-shadow: none;
            padding: 20px 10px 0px 10px;
            margin-bottom: 0px;
        } */

    .cost-chart {
        margin-top: 20px;
    }

    .summary {
        /* padding-top: 0px; */
        display: flex;
        gap: 8px;

        #selected {
            /* background-color: rgba(255, 255, 255, 0.25) */
            box-shadow: #5d99d17e 0px 5px 15px 0px;
            #utility-title {
                color: black;
            }
        }

        .alert {
            padding: 9px;
            margin-bottom: 10px;
            #selected {
                box-shadow: #5d99d17e 0px 5px 15px 0px;
            }
        }

        button {
            padding: 15px;
        }

        .utility-summary-header {
            display: flex;
            align-items: center;
            padding-bottom: 5px;
            justify-content: space-between;
            width: 100%;
            /* margin-left: -22px; */

            p {
                font-size: 25px;
            }

            .utility-divider {
                height: 2px;
                background-color: black;
                flex-grow: 1;
            }
        }

        @media (max-width: 980px) {
            button {
                width:25%;
            }
            img {
                width: 7vw;
                height: 4vw;
                min-width: 43px;
                min-height: 26px;
                margin-bottom: 0px !important;
            }
            p#utility-title {
                font-size: 16px;
            }
            .summary-info {
                font-size: 12px;
            }
        } 
        
        @media (max-width: 560px) {
            flex-wrap: wrap;
            width: 100%;
            
            button {
                width: 48%;
                padding-left: 2vw;
            }
            img {
                width: 60px;
                height: 30px;
            }
        }
    }

    .totals-header {
        width: 100%;
        margin-right: 2.5px;
        margin-left: 2.5px;
        margin-bottom: 10px;
        padding-top: 0px;
        padding-bottom: 10px;

        .card-container {
            display: flex;
            flex-direction: row;    
        }

        h5 {
            margin-top: 25px;
        }

        @media (max-width: 980px) {
            .card-container {
                display: flex;
                flex-direction: column;    
            }
        }

        @media (max-width: 560px) {
            .card-container {
                display: flex;
                flex-direction: row;    
            }
    
            h5 {
                margin-top: 10px;
            }
        }
    }
}

/* styling for energy popover */
.popover-data{ 

    #consumption-text{
        font-size: 14px;
        display: flex;
        text-align: left;
        /* justify-content: center; */
    }

    #difference-text{
        margin-top: 9px;
        margin-left: -15px;
        padding-right: 6px;
        font-size: 13px;
    }

    #consumption-bar-left, #consumption-bar-right {
        height: 100%;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        padding-bottom: 8px;
        border-left: 1px solid black;
        background-repeat: no-repeat;
        background-size:  90px 100%;
    }
    
    #consumption-bar-left {
        margin-left: auto;
        background-image: linear-gradient(to right, yellow 1%, green 100%) ;
        transform: rotate(180deg);
    }

    #consumption-bar-right {
        background-image: linear-gradient(to right, yellow 1%, orange 50%, red 100%) ;
    }
    
    #consumption-amount{
        font-size: 11px;
        position: absolute;
        left: 57.5%;
        top: 75%;
        margin-top:9px;
    }

    .consumption-container {
        width: 70px;
        height: 9px;
        padding-bottom: 8px;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
        margin-top: 7%;
        justify-content: center;
        align-items: center;
    }

    .consumption-wrapper{
        display: flex;
        justify-content: center;
    }
}

.totals {
    margin-top: 20px;
    margin-bottom: 20px;
    color: black;
    font-family: var(--font-stack);

    h5 {
        color: grey;
        font-size: 18px;
        font-weight: 600;
        margin-top: auto;
        margin-bottom: auto;
    }

    h5#difference {
        margin-top: clamp(0px,1vw,14px);
        color: black; 
        font-size: 24px;
        font-weight: 800;
    }

    h5#study, h5#simulated {
        font-size: 20px;
        font-weight: 600;
        margin-top: clamp(0px,1vw,20px);
        margin-bottom: 0px;
        width: 50%
    }

    h5#noinput {
        font-size: clamp(15px,1.9vw, 22px);
        margin: 0px;
        margin-bottom: 5px;
        color: #000000;
    }

    .header {
        font-size: 20px;
        font-weight: 800;
        line-height: 1.2;
    }

    .amount {
        font-size: 30px;
        font-weight: 800;
    }

    .original-container {
        flex-wrap: wrap;
    }

    .big {
        font-size: 60px;
        font-weight: 800;
    }

    .medium {
        font-size: clamp(18px, 2.5vw, 40px);
        font-weight: 800;
    }

    .small {
        font-size: 24px;
        font-weight: 600;
    }
    
    .smaller {
        font-size: clamp(14px,1.5vw,18px);
        font-weight: 600;
    }

    .smaller#range {
        margin-top: clamp(-15px,-1vw,0px);
    }
    
    .right {
        margin-top: -10px;
        text-align: right;
    }
    
    #portfolio {
        margin-top: -34px;
    }
    #portfolio-date {
        font-size: 16px;
    }
    .positive {
        color: var(--positive);
    }
    .negative {
        color: var(--negative);
    }
    .nill {
        color: black;
    }
    .vertical {
        margin-top: auto;
        margin-bottom: auto;
    }

    p {
        color: grey;
        font-size: 12px;
        font-weight: 200;
        margin-top: -8px;
        margin-bottom: 0px;
    }

    p#percentage {
        margin-top: -20px;
        font-weight: 800;
    }

    img {
        max-width: 25px;
        max-height: 25px;
        min-width: 20px;
        min-height: 20px;
        height: 4vw;
        width: 4vw;
    }

    .circle-legend {
        --legend-size: min(1.5vw,20px);
        --legend-border-radius: 100%;
        margin: auto;
        margin-left: 0px;
        margin-right: 10px;
        &#study-bm {
            background-color: var(--vc-yellow);
        }

        &#vc-bm {
            background-color: var(--vc-green);
        }

        &#true-bm {
            background-color: var(--vc-blue);
        }
    }

    @media (max-width: 980px) {
        .amount#original{
            font-size: clamp(12px,2vw,30px);
        }
        .big {
            font-size: 46px;
        }
        h5#original {
            font-size: clamp(12px,2vw,18px);
        }
        .smaller#range {
            text-align: left !important;
            margin-left:30px;
            width: 100%;
        }
        .smaller#difference {
            text-align: left !important;
        }
        #portfolio-date, .smaller {
            font-size: 14px;
        }
    } 

    @media (max-width: 560px) {
        margin-top: 0px;
        margin-bottom: 0px;
        width: 100% !important;
        .amount#original{
            font-size: clamp(14px,5vw,18px);
        }
        .big {
            font-size: 30px;
        }
        .circle-legend {
            width: 0%;
            margin-right: 0px;
        }
        h5#original, .medium {
            font-size: clamp(14px,5vw,20px);
        }
        h5#difference {
            font-size: 22px;
        }
        h5#study, h5#simulated {
            font-size: 18px;
        }
        .original-container {
            flex-wrap: nowrap;
        }
        #portfolio-date {
            font-size: 13px;
        }
        .small {
            font-size: 20px;
            font-weight: 600;
        }
        .smaller#range {
            font-size: clamp(12px,1.5vw,18px);
            margin-left: 0px;
        }
        #difference {
            display: flex !important;
            flex-direction: row !important; 
        }
        p#percentage {
            margin-top: -16px;
        }
    }
}

.totals-pdf {
    margin-top: 20px;
    margin-bottom: 20px;
    color: black;
    font-family: var(--font-stack);

    h5 {
        color: grey;
        font-size: 18px;
        font-weight: 600;
        margin-top: auto;
        margin-bottom: auto;
    }

    h5#difference {
        margin-top: 14px;
        color: black; 
        font-size: 24px;
        font-weight: 800;
    }

    h5#study, h5#simulated {
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 0px;
        width: 50%
    }

    h5#noinput {
        font-size: clamp(15px,1.9vw, 22px);
        margin: 0px;
        margin-bottom: 5px;
        color: #000000;
    }

    .header {
        font-size: 20px;
        font-weight: 800;
        line-height: 1.2;
    }

    .amount {
        font-size: 30px;
        font-weight: 800;
    }

    .original-container {
        flex-wrap: wrap;
    }

    .big {
        font-size: 60px;
        font-weight: 800;
    }

    .medium {
        font-size: 40px;
        font-weight: 800;
    }

    .small {
        font-size: 24px;
        font-weight: 600;
    }
    
    .smaller {
        font-size: 18px;
        font-weight: 600;
    }

    .smaller#range {
        /* margin-top: clamp(-15px,-1vw,0px); */
    }
    
    .right {
        margin-top: -10px;
        text-align: right;
    }
    
    #portfolio {
        margin-top: -34px;
    }
    #portfolio-date {
        font-size: 16px;
    }
    .positive {
        color: var(--positive);
    }
    .negative {
        color: var(--negative);
    }
    .nill {
        color: black;
    }
    .vertical {
        margin-top: auto;
        margin-bottom: auto;
    }

    p {
        color: grey;
        font-size: 12px;
        font-weight: 200;
        margin-top: -8px;
        margin-bottom: 0px;
    }

    p#percentage {
        margin-top: -20px;
        font-weight: 800;
    }

    img {
        max-width: 25px;
        max-height: 25px;
        min-width: 20px;
        min-height: 20px;
        height: 4vw;
        width: 4vw;
    }

    .circle-legend {
        --legend-size: 20px;
        --legend-border-radius: 100%;
        margin: auto;
        margin-left: 0px;
        margin-right: 10px;
        &#study-bm {
            background-color: var(--vc-yellow);
        }

        &#vc-bm {
            background-color: var(--vc-green);
        }

        &#true-bm {
            background-color: var(--vc-blue);
        }
    }
}

/* Dashboard page */
.Dashboard {
    .chart-label {
        div {
            font-size: 15px;
        }

        .legend {
            display: flex;
            gap: 10px;
        }

        p {
            margin-left: 35px;
        }

        .square-legend {
            &#portfolio-actual {
                background-color: var(--vc-blue);
            }

            &#investments {
                background-color: var(--vc-green);
            }

            &#portfolio-estimate {
                background-color: var(--vc-yellow);
            }
        }

        @media (max-width: 980px) {
            display: none; /* hide label when screen is small */
        }           
    }

    .nav {
        a {
            color: black;
            font-family: var(--font-stack);
            font-size: 24px;
            font-weight: 800;
            line-height: 1.2;
        }

        @media (max-width: 980px) {
            a {
                font-size: 20px;
            }
        }

        @media (max-width: 560px) {
            a {
                font-size: 16px;
                padding: 8px 0px;
            }
        }
    }
    /* react table */
    .react-table {
        /* padding: 10px 0; */

        .stick-header-style {
            .sorter {
                display: inline-block;
                font-size: 7px;
                margin-left: 5px;

                #sorted-ascending,
                #sorted-descending {
                    color: black;
                }

                #unsorted-descending,
                #unsorted-ascending {
                    color: grey;
                }

                #sorted-ascending,
                #unsorted-ascending {
                    margin-bottom: -3px;
                }
            }

        }

        .breakdown-body {

            .table-cell,
            img {
                padding-left: 8px;
            }

            img {
                padding-top: 2.5px;
            }

            .table-cell {
                height: 50px;
                display: flex;
                align-items: center;
                font-size: 18px;
                white-space: nowrap;
            }

            td {
                padding: 0;
            }
        }

        th {
            padding: 10px 12px;
        }
        td {
            padding: 8px 10px;
        }

        &#all-events {
            margin-top: 20px;
            height: 285px;
            
            .header-hover {
                cursor: default;
                /* padding: 10px 12px; */
            } 
        }

        &#all-investments {
            height: 720px;
        }
    }
    
    .carousel-button:hover {
        background: #5d99d19e;
    }

    .suggested-action {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 600;
        font-family: var(--font-stack);
        text-align: center;


        /* TODO: clean this up */
        #first-spacing {
            line-height: 1.4em;
        }

        #second-spacing {
            line-height: 1.4em;
        }

        .global-spacing {
            margin-top: 5%;
        }

        .amount-adjuster {
            font-size: 150%;
            font-weight: 800;
        }

        .target-date {
            font-size: 120%;
            font-weight: 800;
            margin-bottom: 0px;
        }

        #more-info {
            width: 60%;
            background: #5d99d14b;
            border-radius: 20px;
            border: none;
            font-weight: bold;
            padding: 2%;
            /* padding inside the button*/
            box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 8px;
        }

        .action-event {
            font-size: larger;
        }

        .days-remaining {
            font-size: large;
            font-size: 80%;
        }

        .available-cash {
            font-size: 80%;
            height: 16px;
        }

        @media (max-width: 980px) {
            font-size: 16px;
            padding-left: 6px;
            padding-right: 6px;
            .amount-adjuster {
                font-size: 18px;
                font-weight: 800;
            }
            #more-info {
                padding-left: 2px;
                padding-right: 2px;
                min-width: 78px;
            }
            .target-date {
                font-size: 18px;
                font-weight: 800;
                margin-bottom: 0px;
            }    
        }        
    }

    .investment-breakdown {
        gap: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        p {
            margin: 10px 0px;
        }

        h3 {
            text-align: center;
        }

        .investment-card {
            flex-basis: 243.8px; /* relative to $narrow-page-width (1024px) */
            height: 177px;
            padding-top: 26px;
            padding-bottom: 26px;
            border-radius: 10px;
            border-width: 0;
            cursor: default;
            background: #ffffff;
            display: flex;
            align-items: center;
            flex-direction: column;

            .header-alignment {
                margin-bottom: 0.25rem;
                line-height: 1.1;
            }

            .total-amount {
                width: 70%;
                height: 40px;
                /* box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px; */
                background: white;
                border-radius: 10px;
                /* border-style: solid;
               border-color: black; */
                /* border-width: thin; */
                font-size: 175%;
                font-family: var(--font-stack);
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px;
            }

            .three-dot-icon {
                width: 13%;
                height: 20px;
                background: rgba(251, 213, 126, 0.3);
                border-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 8px;
                cursor: pointer;
                padding-bottom: 8px;
            }

            @media (max-width: 980px) {
                flex: 3 0 290px;
            }        
        }
    }
}

/* off canvas tutorial component */
:root .offcanvas {
    --bs-offcanvas-width: 500px
}
.carousel-button, .offcanvas-tutorial {
    font-family: var(--font-stack);

    .offcanvas-title {
        font-size: 24px;
    }

    --border: 1px solid rgba(0, 0, 0, 0.064);  
    .carousel-button, .offcanvas-body {
        padding-top: 0;
        .cashflow-table {
            margin-bottom: 1.25rem;
            padding: 15px;
            font-size: 12px;
            border: var(--border); 
        
            .column#thin {
                min-width: 48px;
            }
            --padding-horizontal: 5.5px;
            .column {
                min-width: 65px;
                u {
                    padding: 0px var(--padding-horizontal);
                }
            }
            .cell {
                text-decoration: none;
                padding: 0px var(--padding-horizontal);
            }
            .hovered {
                background-color: #5d99d17f;
            }
        }
        .cell-description {
            padding: 15px;
            border: var(--border); 
        }
    }
}

/* investment breakdown popover table */
#investment-popover {
    max-width: 450px;
}

.submit-settings-modal {
    font-family: var(--font-stack);
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    margin-top: 10%;

    .modal-header .modal-title {
        font-weight: 600;
    }
    .modal-width {
        max-width: 500px;
    }
    .save-config-table {
        margin-bottom: 0px;
        thead {
            font-size: 18px;
        }
        tbody tr td {
            padding-top: 5px;
            padding-bottom: 5px;
        }
        tbody tr#group-top td {
            padding-top: 5px;
            padding-bottom: 0px;
        }
        tbody tr#group-middle td {
            padding-top: 0px;
            padding-bottom: 0px;
        }
        tbody tr#group-bottom td {
            padding-top: 0px;
            padding-bottom: 5px;
        }
    
    }

    /* h5 {
         margin-bottom: 2px;
         font-size: 20px;
         font-weight: 600;
    } */

    h5 {
        font-size: 20px;
        font-weight: 200;
        margin-bottom: 4px;
    }

    p {
        font-size: 20px;
        margin-bottom: 0px;
    }
}

.settings-history-modal {
    font-family: var(--font-stack);
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    margin-top: 10%;

    .modal-header .modal-title {
        font-weight: 600;
    }
    .modal-width {
        max-width: 1000px;
    }

    .save-config-table {
        margin-bottom: 0px;
        max-height: 25vh;
        overflow-y: auto;
        display: block;

        thead {
            font-size: 18px;
        }
        tbody tr td {
            padding-top: 5px;
            padding-bottom: 5px;
        }
        tbody tr#group-top td {
            padding-top: 5px;
            padding-bottom: 0px;
        }
        tbody tr#group-middle td {
            padding-top: 0px;
            padding-bottom: 0px;
        }
        tbody tr#group-bottom td {
            padding-top: 0px;
            padding-bottom: 5px;
        }
        
        td {
            background-color: transparent;
        }
    }
}

/* global modal on report card page */
.rc-modal {
    font-family: var(--font-stack);
    
    .modal-header .modal-title {  
        font-weight: 600;
    }
    .modal-width {
        max-width: 1200px;
    }

    width: 100%;
    display: flex;
    padding-bottom: 10px;

    .grading-breakdown {
        margin-left: 1.5%;
        margin-top: 15px;
        line-height: 110%;
        .color-gradient {
            font-size: 28px;
            top: 20px;
        }
        .progress {
            height: 14px;
            margin-top: 5px;
            margin-bottom: 12px;
        }
        .learn-more {
            font-family: var(--font-stack);
            font-size: 16px;
            color: grey;
            width: 15%;
            height: 20px;
            /* background: rgba(251, 213, 126, 0.3); */
            /* border-radius: 6px; */
            /* box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 8px; */
            text-align: right;
            cursor: pointer;
        }
    }

    .grading-summary {
        height: 280px;
        overflow-y: auto;
        width: 100%;
        margin-right: 12px;
    }

    .grading-header {
        width: 650px;
        margin-right: 30px;
        /* margin-right: 10px; */
        margin-bottom: 10px;
        display: flex;
        /* align-items: center; */

        .grade {
            padding: 6px;
            width: 25%;
            margin-right: 2%;
            background-color: rgba(20, 22, 22, 0.164);
            border-radius: 25px;
            text-align: center;
            font-family: var(--font-stack);
            font-weight: 600;
            font-size: 40px;
        }

        p {
            font-size: 20px;
        }

        h5 {
            margin-bottom: 0;
            font-family: var(--font-stack);
            font-weight: 600;
        }
    }
    @media (max-width: 980px) {
        .grading-header{
            width:50vw;
            .grade{
                font-size: 30px;
            }
            h5{
                font-size: 16px;
            }
        }
        .grading-summary{
            margin-right:0px;
        }
        .grading-breakdown{
            font-size: 15px;
            .learn-more{
                width: 38%;
            }
        }
    }

    @media (max-width: 560px) {
        .grading-header{
            width: 100%;
        }
        .grading-breakdown{
            margin-bottom: 40px;
        }
    }
}

.onboarding-modal {
    font-family: var(--font-stack);
    
    .modal-header .modal-title {
        font-weight: 600;
    }
    .modal-width {
        max-width: 1200px;
    }

    width: 100%;
    display: flex;
    padding-bottom: 10px;
}

.totals-modal {
    font-family: var(--font-stack);
    
    .modal-header .modal-title {
        font-weight: 600;
    }
    .modal-width {
        max-width: 800px;
    }

    width: 100%;
    display: flex;
    padding-bottom: 10px;
}

.delete-modal {
    font-family: var(--font-stack);
    
    .modal-header .modal-title {
        font-weight: 600;
    }
    .modal-width {
        max-width: 400px;
    }
}

.rename-modal {
    font-family: var(--font-stack);
    
    .modal-header .modal-title {
        font-weight: 600;
    }
    .modal-width {
        max-width: 400px;
    }
}

.document-ref-modal {
    font-family: var(--font-stack);

    .modal-header .modal-title {
        font-weight: 600;
    }
    .modal-width {
        max-width: 1000px;
    }
    .modal-body {
        padding: 0;
        overflow-y: hidden;
    }
    
    .tab-content {
        padding: 15px;
        padding-top: 5px;
        height: 60vh;
        border-left: 1px solid #e7e7e7; 
        overflow-y: auto;

    }
    .nav-item {
        a {
            color: #3f3e3e !important;
            font-size: large;
            font-weight: 600;
        }
    }

    .document-section {
        margin-bottom: 20px; 
    }
    h4 {
        font-weight: 600; 
        margin-bottom: 8px; 
    }
    pre {
        white-space: pre-wrap; 
        word-wrap: break-word; 
        overflow-wrap: break-word; 
        margin: 0; 
        font-family: inherit;
        font-size: inherit; 
        line-height: 1.5; 
    }  
    .document-separator {
        margin: 20px 0;
        border: none;
        border-top: 3px solid #000000; 
    }

    .missing-message {
        padding: 16px;
    }
}

.select-study-modal {
    font-family: var(--font-stack);

    p {
        margin-bottom: 0px;
    }

    .modal-header {
        display: grid;
        .modal-title {
            font-size: 32px;
            font-weight: 600;
        }
    }
    .modal-width {
        max-width: 1200px;
        max-height: 800px;
    }

    .modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        thead {
            font-size: 24px;
            cursor: default;
        }
        tbody {
            font-size: 36px;
            cursor: pointer;
        }

        @media (max-width: 560px) {
            thead{
                font-size: 20px;
            }
            tbody{
                font-size: 16px;
            }
        }
    }
}

.more-info-modal {
    font-family: var(--font-stack);
    margin-top: 15%;

    .modal-body {
        background-color: var(--background-color);
    }
    .modal-width {
        max-width: 850px;
    }
    .modal-header h4 {
        font-weight: 600;
    }
}

.timeout-modal {
    .modal-width {
        max-width: 500px;
    }
}

.snapshot-modal {
    font-family: var(--font-stack);

    .modal-header .modal-title {
        font-weight: 600;
    }
    .modal-width {
        max-width: 550px;
    }

    .manage-cash-balance-header {
        margin-bottom: 10px;

        .component-title {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            gap: 10px;
            margin-top: 6px;
            width:100%;

            h5 {
                font-size: 22px;
                margin-bottom: 0;
                font-weight: 600;
            }
        }

        .last-updated {
            font-size: 12px;
            margin-top: -2px;
            text-align: right;
            float: right;
            width: 120%;
        }

        @media (max-width: 560px) {
            .component-title {
                h5 {
                    font-size: 18px;
                }
                div { 
                    width: auto !important;   
                }
            }
        }
    }

    .snapshot-form {
        gap: 10px;
        margin-top: 6px;

        @media (max-width: 560px) {
            flex-wrap: wrap;
        }
    
    }

    .dollar-prefix {
        display: inline-block;
        position: relative;

        &:before {
            padding-left: 3px;
            padding-top: 4px;
            text-align: center;
            position: absolute;
            content: "$";
            left: 5px;
            top: 3px;
        }

        input {
            text-align: right;
            padding-left: 12px;
        }
    }

}

/* styling for calcualtor config modals */
.config-modal {
    font-family: var(--font-stack);
    
    .modal-header .modal-title {
        font-weight: 600;
    }
    .modal-width {
        max-width: 800px;
    }
}

.modal-download {
    font-family: var(--font-stack);
    margin-top: 50px;

    .modal-body {
        background-color: darkgray;
        border-radius: 0px 0px 8px 8px;
        padding: 0px;
    }
    .modal-width {
        max-width: 1550px;
    }
    .modal-header h4 {
        font-weight: 600;
    }
}

.modal-download-mini {
    font-family: var(--font-stack);
    margin-top: 50px;

    .modal-header{
        border-width: 2px;
    }

    .modal-header h4 {
        font-weight: 600;
        border: none;
    }

    .modal-body {
        padding: 10px 0 0 18px;
        border: none;
    }

    .modal-width {
        max-width: 70%;
    }

    .modal-footer {
        border: none;
    }
    @media (max-width: 600px) {
        .modal-width {
            max-width: 95%;
        }
    }     
}


.options-menu {
    position: relative;
    display: inline-block;

    .menu-button {
        display: flex;
        height: 28px;
        width: 28px;
        margin-top: 7px;
        border-radius: 20px;
        border-width: 0px;
        background-color: inherit;
    }

    .menu-button:hover {
        background-color: #ced4da;
    }
  
    .menu-options {
      position: absolute;
      top: 30px;
      right: 0;
      background: white;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      z-index: 10;
      width: 125px;
  
      .menu-item {
        display: block;
        width: 100%;
        padding: 8px 12px;
        background: none;
        border: none;
        border-radius: 8px 8px 0px 0px;
        outline: none;
        cursor: pointer;
        transition: background 0.2s;
  
        &:hover {
          background: #f0f0f0;
        }
  
        &.menu-item-danger {
          color: red;
          border-radius: 0px 0px 8px 8px;
        }
      }
    }
  }
  
/* Manage data page */
.manage-data {
    --outer-border: 1px solid #dee2e6;

    .content {
        max-width: var(--content-width-narrow);
        margin-top: 50px;
        padding: 0 0 20px 0;
    }

    .tab-content {
        background-color: white;
        height: 1000px;
        overflow-y: auto;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-left: var(--outer-border);
        border-right: var(--outer-border);
        border-bottom: var(--outer-border);
    }

    /* makes cursor pointer for tabs */
    .nav-item {
        cursor: pointer;
    }

    .component-container {
        width: 75%;
        border: var(--outer-border);
    }

    .buttons {
        align-items: baseline;
        margin-bottom: 12px;
    }

    .data-overview {
        text-align: center;

        h3 {
            padding: 40px 0px;
        }

        .upload-button {
            margin-top: 40px;
        }
    }

    .processing {
        flex-direction: column;
        margin-top: 150px;

        h3 {
            margin-bottom: 30px;
        }
    }

    .table-view {
        margin-top: 5px;
        padding: 20px;
    }

    .react-table {
        height: 900px;
        border-radius: 0px;

        .header-hover {
            cursor: default;
        }
    }

    @media (max-width: 980px) {
        .content {
            max-width: 600px;
        }
        .data-overview h3 {
            font-size: 18px;
            padding: 20px 0px;
        }
        .component-container {
            font-size: 14px;
            padding: 10px;
        }
        .nav.nav-tabs {
            font-size: 14px;
            .nav-item a{
                padding: 6px 12px;
            }
        }
    }        
}

/* whole bunch of manage-data upload modal formatting*/
#form-file-upload {
    max-width: 100%;
    text-align: center;
    position: relative;

    #input-file-upload {
        display: none;
    }

    #label-file-upload {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 2px;
        border-radius: 1rem;
        border-style: dashed;
        border-color: #cbd5e1;
        background-color: #dde7f19d;
        margin-bottom: 12px;

        &.drag-active {
            background-color: #ffffff;
        }
    }

    .additional-data {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;

        h6 {
            margin-bottom: 0;
        }
    }

    .file-uploaded {
        margin-right: 20px;
        padding-top: 6px;
        padding-bottom: 12px;
    }
}

.documents-referenced {
    
    .finder {
        width: 200px;
        padding: 5px;
       
        position: fixed; 
        display: flex;
        flex-direction: column;
        z-index: 1;
        
        .widget {
            white-space: nowrap;       
            overflow: hidden;        
            text-overflow: ellipsis;

            
            border: none;

            margin: 3px;
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 11px;

            border-radius: 10px;
            text-align: left;
            font-weight: 600;
            color: #3f3e3e;
            border-color: #b1a9a9;
        }   
        .selected {
            background-color: #adb5bd;
            
        }

        .header {
            display: flex;
            padding-left: 9px;
            padding-top: 3px;
            padding-bottom: 10px;
            font-size: large;
            font-weight: 600;
            color: #3f3e3e;
            width: 100%;
            
        }
    }
}

.document {
    margin-top: -1px;
    padding-left: 200px;
}

/* Styling for download pdf button */
.export-button {
    display: flex !important;
    align-items: center !important; /* Centers icon and text vertically */
    justify-content: center !important; /* Centers content horizontally */
    gap: 5px !important; /* Adds space between the icon and text */
    outline: none !important;
    cursor: pointer !important;
    text-align: center !important;
    border: 1px solid #cfd5d9 !important;
    padding: 5px 25px !important;
    margin-bottom: 3px;
    height: 35px !important;
    width: 113px !important;
    color: #202223 !important;
    background-color: #ffffff !important;
    border-radius: 6px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}
.export-button:hover {
    background-color: #f6f6f7 !important; /* Change background color on hover */
}

.export-button img {
    width: 16px !important; /* Icon width */
    height: 16px !important; /* Icon height */
}

/* download containers */
.download-container {
    position: relative; 
    width: 1250px;
    height: 1600px;
    margin: 10px 0px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    border: 0px;
    text-align: left;
    border-radius: 0px;
    box-shadow: rgb(59 59 59 / 5%) 0px 5px 15px 0px;
}

.download-container .watermark {
    position: absolute;
    width: 250px;
    height: 75px;
    right: 30px;
    bottom: 15px;
}

.download-container .watermark svg{
    width: 100%;
    height: 100%;
    opacity: 0.5; 
    pointer-events: none; 
    user-select: none; 
}

/* ---------------------------------------------
/ Print Watermark
/ ---------------------------------------------*/
